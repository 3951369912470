@font-face {
  font-family: Lorea;
  src: url(./assets/fonts/Lorea.ttf);
}
@font-face {
  font-family: Everleigh;
  src: url(./assets/fonts/Everleigh.otf);
}
@font-face {
  font-family: Anime;
  src: url(./assets/fonts/Anime.otf);
}
@font-face {
  font-family: LightningScript;
  src: url(./assets/fonts/LightningScript.otf);
}
@font-face {
  font-family: Blackpast;
  src: url(./assets/fonts/Blackpast.otf);
}
@font-face {
  font-family: Sega;
  src: url(./assets/fonts/SEGA.TTF);
}
@font-face {
  font-family: Wonderland;
  src: url(./assets/fonts/Wonderland.ttf);
}
@font-face {
  font-family: CallingFont;
  src: url(./assets/fonts/CallingFont.ttf);
}
@font-face {
  font-family: Nourandilla;
  src: url(./assets/fonts/Nouradilla.ttf);
}

/* RELIC ISLAND ///////////////////////////// */
@font-face {
  font-family: RelicIslandOTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island.otf);
}
@font-face {
  font-family: RelicIslandTTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island.ttf);
}
@font-face {
  font-family: RelicIsland1OTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island-1.otf);
}
@font-face {
  font-family: RelicIsland1TTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island-1.ttf);
}
@font-face {
  font-family: RelicIsland2OTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island-2.otf);
}
@font-face {
  font-family: RelicIsland2TTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island-2.ttf);
}
@font-face {
  font-family: RelicIslandFrameOTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island-Frame.otf);
}
@font-face {
  font-family: RelicIslandFrameTTF;
  src: url(./assets/fonts/Relic-Island/Relic-Island-Frame.ttf);
}
@font-face {
  font-family: PixeloidSans;
  src: url(./assets/fonts/PixeloidSans-JR6qo.ttf);
}
@font-face {
  font-family: PixeloidSansBold;
  src: url(./assets/fonts/PixeloidSansBold-GOjpP.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: sans-serif;
}

:root {
  /* COLORS */
  --color-1: #222220;
  --color-2: #243b35;
  --color-2: darkslategray;
  --color-2-op5: rgb(47, 79, 79, 0.5);
  --color-3: #017b7e;
  --color-3-op5: rgb(1, 123, 126, 0.5);
  --color-4: #c7cac1;
  --color-4-op: rgb(199, 202, 193, 0.5);
  --color-5: #e2e6e7;

  /* SHADOWS */
  --card-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.5);
  --tut-card-shadow: 0 0 7px 1px rgb(171, 171, 171);
}

body {
  background: linear-gradient(90deg, lightgray, gray, lightgray);
}
