.dark-light-theme-btn {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 70px;
  left: 10px;
  z-index: 14;
  cursor: pointer;
  border-radius: 50%;
  background: radial-gradient(circle at 40% 40%, white, lightgray 50%, black);
  box-shadow: 0 0 10px 1px rgb(0, 0, 0, 0.5);
  transition: all 1s linear;
}
.dark-light-theme-btn:hover {
  background: rgb(255, 255, 200);
  box-shadow: 0 0 10px 1px orangered;
  transition: all 1s linear;
}

.javascript-color-card {
  max-width: 250px;
  min-height: 100px;
  margin: 30px auto;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 5px 1px rgb(0, 0, 0, 0.3);
  cursor: pointer;
  background: white;
}

.javascript-color-card .color-card-p-1 {
  width: fit-content;
  font-size: 20px;
  opacity: 0;
  transition: all 1s ease-in-out;
}
.color-card-p-2 {
  opacity: 0;
  transition: all 1s ease-in-out 1s;
}

.tutorial-heading-image {
  width: 100%;
  max-width: 300px;
  aspect-ratio: 3/2;
  border: 1px solid;
  border-radius: 5px;
  background: url(../../assets/gifs/w300-frames/spritesheet.png);
  animation: nav-menu 5s steps(15) infinite;
}
@keyframes nav-menu {
  90% {
    background-position: -4500px, 0;
  }
  100% {
    background-position: -4500px, 0;
  }
}

.tutorial-heading-image.gif {
  background: url(../../assets/gifs/nav-menu-btn-gif-w300.gif);
  background-position: 0 0;
}
