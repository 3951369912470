.canvas-page{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.franks-link {
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 30px auto;
  padding: 0 10px 0 0;
  font: 600 20px sans-serif;
  border: 1px solid blue;
  border-radius: 10px;
  background: white;
  box-shadow: 0 1px 7px 1px rgb(0, 0, 0, 0.5);
}

.franks-link i {
  width: 50px;
  height: 40px;
  background: url(../../assets/icons/youtube-brands.svg) 50% / 70% no-repeat;
}

.controls-info p{
  line-height: 30px;
}

p.mobile-landscape-alert {
  max-width: 500px;
  padding: 10px;
  margin: auto;
  color: red;
  font: 600 20px sans-serif;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid;
}

.canvas-game-link {
  width: fit-content;
  min-width: 200px;
  min-height: 50px;
  margin: 30px auto 100px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 800 16px sans-serif;
  color: white;
  text-transform: uppercase;
  border: 5px solid rgb(255, 0, 255, 0.5);
  border-radius: 50px;
  box-shadow: 0 1px 7px 1px rgb(0, 0, 0, 0.5);
  background: linear-gradient(30deg, blue, purple);
}
.canvas-game-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88888;
  background: black;
  pointer-events: all;
}
.canvas-game-back-btn {
  padding: 13px;
  position: fixed;
  top: 0;
  right: 0;
  border: 2px solid white;
  border-radius: 100px;
  color: red;
  background: transparent;
  cursor: pointer;
}
#canvas-game {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  /* font-family: Sega; */
}
#layer1,
#layer2,
#layer3,
#layer4,
#player,
#angler1,
#angler2,
#lucky,
#projectile,
#gears,
#hivewhale,
#drone,
#smokeExplosion,
#fireExplosion {
  display: none;
}

#x-pad {
  width: 120px;
  height: 120px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  border: 2px solid lightgray;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}

#x-pad::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid lightgray;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

#x-pad div {
  width: 120px;
  height: 60px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

#x-pad-up {
  top: -5%;
  transform: rotate(180deg);
}

#x-pad-down {
  top: 53%;
}

#x-pad-left {
  top: 25%;
  left: -30%;
  transform: rotate(90deg);
}

#x-pad-right {
  top: 25%;
  left: 29%;
  transform: rotate(270deg);
}

#x-pad-up::before,
#x-pad-down::before,
#x-pad-left::before,
#x-pad-right::before {
  content: "";
  width: 25px;
  height: 40px;
  border: 1px solid white;
  border-top: none;
  border-radius: 5px;
  position: absolute;
}

#x-pad-up.pressing,
#x-pad-down.pressing,
#x-pad-left.pressing,
#x-pad-right.pressing {
  background: white;
}

#fire-btn {
  width: 80px;
  height: 80px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: white;
  border: 2px solid lightgray;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1px 2px 5px 1px rgb(0, 0, 0, 0.5);
}

#fire-btn.pressing {
  background: red;
}

#start-btn {
  width: 80px;
  height: 40px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: 0 auto;
  border: 1px solid lightgray;
  border-radius: 20px;
  color: lightgray;
  font: 600 10px sans-serif;
  background: transparent;
  cursor: pointer;
}
