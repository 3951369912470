.spinning-top-example-1 {
  width: 50px;
  height: 100px;
  border: solid;
  position: relative;
  top: 50px;
}
.spinning-top-example-2 {
  border: solid;
  transform: rotateY(50deg) scale(1.5);
  animation: none;
}
.spinning-top-example-2 .top-left-example {
  background: red;
}
.spinning-top-example-2 .top-right-example {
  background: blue;
}
.spinning-top-example-2 .top-back-example {
  background: yellow;
}
