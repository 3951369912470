.spinning-top-container {
  width: 100%;
  max-width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 600px;
  perspective-origin: 50% -10%;
  overflow: hidden;
  border: 1px solid var(--color-3);
  border-radius: 5px;
  background: linear-gradient(white, skyblue 30% 50%, navy 52%, turquoise, white);
}

.spinning-top {
  height: 100px;
  width: 50px;
  position: relative;
  top: 50px;
  transform-style: preserve-3d;
  animation: spinning-top-rotateY 3s linear infinite;
  cursor: pointer;
}

@keyframes spinning-top-rotateY {
  to {
    transform: rotateY(-360deg);
  }
}

.spinning-top.anim {
  animation: spinning-top-anim-2 8s ease-in infinite;
}

@keyframes spinning-top-anim-2 {
  20% {
    top: 50px;
    right: 0;
  }
  40% {
    top: -100px;
    right: 0;
  }
  50% {
    top: -80px;
  }
  60% {
    top: -70px;
    right: 125px;
  }
  70% {
    top: -70px;
    right: -125px;
  }
  80% {
    right: 125px;
  }
  85% {
    right: -125px;
  }
  90% {
    top: 50px;
    right: 125px;
  }
  93% {
    right: -125px;
    transform: rotateY(-30000deg) rotateX(0);
  }
  96% {
    right: -90px;
    transform: rotateY(-31820deg) rotateX(75deg);
  }
  100% {
    right: -90px;
    transform: rotateY(-31820deg) rotateX(75deg);
    animation-timing-function: ease-out;
  }
}

.spinning-top .side {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-origin: 50% 100%;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.spinning-top .front,
.spinning-top .back {
  background: rgb(255, 0, 255, 0.4);
}

.spinning-top .front {
  transform: rotateX(-7deg);
}

.spinning-top .back {
  transform: rotateX(7deg);
}

.spinning-top .left,
.spinning-top .right {
  background: rgb(0, 155, 255, 0.4);
}

.spinning-top .left {
  transform: rotateY(90deg) rotateX(7deg);
}

.spinning-top .right {
  transform: rotateY(90deg) rotateX(-7deg);
}

.spinning-top .top {
  width: 50px;
  height: 50px;
  clip-path: polygon(0% 25%, 25% 25%, 25% 0%, 75% 0%, 75% 25%, 100% 25%, 100% 75%, 75% 75%, 75% 100%, 25% 100%, 25% 75%, 0% 75%);
  top: -24.5px;
  transform-origin: initial;
  transform: rotateX(90deg);
  background: url(../../../assets/logos/webengines-100.png) 50% / 40% no-repeat, rgb(0, 55, 255, 0.4);
}

.spinning-top-container .shadow {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 10px;
  transform: rotateX(90deg);
  border-radius: 50%;
  background: radial-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.05));
}

.shadow.anim {
  animation: shadow-anim 8s ease-in infinite;
}

@keyframes shadow-anim {
  0% {
    bottom: 10px;
    transform: rotateX(90deg) rotateZ(0);
  }
  20% {
    right: 125px;
  }
  40% {
    right: 125px;
  }
  60% {
    bottom: 0;
    right: 250px;
  }
  70% {
    right: 0;
  }
  80% {
    right: 250px;
  }
  85% {
    right: 0;
  }
  90% {
    bottom: 10px;
    right: 250px;
  }
  93% {
    right: 0;
    bottom: 10px;
    transform: rotateX(90deg) rotate(-30000deg);
  }
  96% {
    right: 35px;
    bottom: 55px;
    transform: rotateX(90deg) rotate(-31820deg);
  }
  100% {
    right: 35px;
    bottom: 55px;
    transform: rotateX(90deg) rotate(-31820deg);
  }
}
