.ripples-container {
  width: 400px;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid aqua;
  border-radius: 10px;
  background: navy;
  box-shadow: var(--card-shadow);
}

.ripples-container h1 {
  background: none;
  border: none;
  font-size: 50px;
  color: aqua;
  mix-blend-mode: difference;
  position: absolute;
  z-index: 1;
}

.ripples-container span {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 0 0 25px 5px aqua, 0 0 25px 5px aqua;
  animation: ripples-anim 8s linear infinite;
}

.ripples-container span:nth-child(2) {
  animation-delay: 0.5s;
}
.ripples-container span:nth-child(3) {
  animation-delay: 1s;
}
.ripples-container span:nth-child(4) {
  animation-delay: 1.5s;
}
.ripples-container span:nth-child(5) {
  animation-delay: 2s;
}
.ripples-container span:nth-child(6) {
  animation-delay: 2.5s;
}
.ripples-container span:nth-child(7) {
  animation-delay: 3s;
}
.ripples-container span:nth-child(8) {
  animation-delay: 3.5s;
}
.ripples-container span:nth-child(9) {
  animation-delay: 4s;
}
.ripples-container span:nth-child(10) {
  animation-delay: 4.5s;
}

@keyframes ripples-anim {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
