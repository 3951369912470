.color-cube-ex {
  height: 100px;
  width: 100px;
  position: relative;
  transform-style: preserve-3d;
}
.color-cube-ex.border {
  border: solid black;
}
.color-cube-ex .side {
  height: 100%;
  width: 100%;
  position: absolute;
  border: 2px solid rgba(255, 0, 255, 0.5);
  background: rgb(0, 0, 255, 0.5);
}
.color-cube-ex .left {
  transform: translateX(-50px) rotateY(90deg);
  background: red;
}
.color-cube-ex .right {
  transform: translateX(50px) rotateY(90deg);
  background: blue;
}
.color-cube-ex .back {
  transform: translateZ(-50px);
  background: limegreen;
}
.color-cube-ex .top {
  transform: translateY(-50px) rotateX(90deg);
  background: yellow;
}
.color-cube-ex .bottom {
  transform: translateY(50px) rotateX(90deg);
  background: aqua;
}
.color-cube-ex .front {
  transform: translateZ(50px);
}
.color-cube-ex .shadow {
  box-shadow: 0 0 5px 5px rgb(0, 0, 0, 0.05);
  background: radial-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.05));
  transform: translateY(120px) rotateX(90deg);
}
