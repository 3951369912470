.cube-container {
  width: 100%;
  max-width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 600px;
  perspective-origin: 50%;
  overflow: hidden;
  border: 1px solid var(--color-3);
  border-radius: 5px;
  background: linear-gradient(white, skyblue 30% 50%, purple 52%, magenta, white);
}

.color-cube {
  height: 100px;
  width: 100px;
  position: relative;
  transform-style: preserve-3d;
  animation: color-cube-rotateY 12s linear infinite;
  cursor: pointer;
}
@keyframes color-cube-rotateY {
  to {
    transform: rotateY(360deg);
  }
}

.color-cube.anim {
  animation: color-cube-anim-2 13s ease-in-out;
}
@keyframes color-cube-anim-2 {
  50% {
    transform: rotateX(18000deg) rotateY(180deg);
  }
}

.color-cube .side {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid rgba(255, 0, 255, 0.3);
  transform: translateZ(var(--translateZ, 0)) rotateY(var(--rotateY, 0));
  background: rgb(0, 0, 255, 0.7);
}

.color-cube .front {
  background: url(../../../assets/logos/webengines-100.png) 50% / 70% no-repeat, rgb(0, 0, 255, 0.7);
  --translateZ: 50px;
}

.color-cube .back {
  background: url(../../../assets/logos/webengines-100.png) 50% / 70% no-repeat, rgb(0, 0, 255, 0.7);
  --translateZ: -50px;
  --rotateY: 180deg;
}

.color-cube .left {
  transform: translateX(-50px) rotateY(-90deg);
}

.color-cube .right {
  transform: translateX(50px) rotateY(90deg);
}

.color-cube .top {
  background: rgb(255, 0, 255, 0.5);
  transform: translateY(-50px) rotateX(90deg);
}

.color-cube .bottom {
  background: rgb(255, 0, 255, 0.5);
  transform: translateY(50px) rotateX(90deg);
}

.color-cube .shadow {
  border: none;
  border-radius: 5px;
  background: radial-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.05));
  transform: translateY(120px) rotateX(90deg);
}

.color-cube.anim .shadow {
  background: none;
}
