.tut-page * {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.tut-page {
  padding: 44px 20px;
  background: white;
}

.tut-page header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px;
}

.tut-page h1 {
  padding: 11px;
}

.article-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 30px 0;
  padding: 5px;
  gap: 5px;
  border-radius: 7px;
  box-shadow: var(--tut-card-shadow);
}

.article-info p {
  padding: 5px;
}

.tut-intro,
.tut-outro {
  width: calc(100% - 40px);
  max-width: calc(800px - 40px);
  margin: 20px auto;
}
.tut-outro {
  margin: 50px auto;
}

.open-all-code {
  display: inline;
  text-decoration-line: underline;
  color: blue;
  cursor: pointer;
}

.tutorial-step-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 7px;
  box-shadow: var(--tut-card-shadow);
}

.code-example-container {
  width: 100%;
  position: relative;
  color: black;
}

.all-code-container {
  width: fit-content;
  max-width: 100%;
  display: none;
}

.all-code-container.open {
  display: flex;
}

.close-all-code {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
}

.code-example {
  max-width: 100%;
  padding: 22px;
  overflow-x: auto;
  font: 15px Consolas, "Courier New", monospace;
  background: hsl(0deg 0% 97%);
}

.copy-code-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  border-top-left-radius: 9px;
  box-shadow: inset 0 0 4px 1px rgb(0, 0, 0, 0.05);
  background: url(/src/assets/icons/copy-solid-color.svg) 70% 70% / 50% no-repeat hsl(0deg 0% 97%);
  cursor: pointer;
}

.copy-code-btn::after {
  content: "code copied";
  width: 80px;
  height: fit-content;
  position: absolute;
  right: 40px;
  bottom: 7px;
  border-bottom: 1px solid gray;
  color: gray;
  display: none;
}

.copy-code-btn.clicked.copy-code-btn::after {
  display: block;
}

.border-ex-1 {
  width: 100%;
  max-width: 300px;
}
