.header-right-side {
  flex-direction: row-reverse;
}

.close-nav-overlay {
  width: 100%;
  height: calc(100% - 50px);
  position: fixed;
  top: 60px;
  left: 0;
  display: none;
  background: rgb(0, 0, 0, 0.3);
}

.close-nav-overlay.open {
  display: block;
}

/* MAIN NAV MENU LARGE SCREENS ///////////////////////// */
.nav-menu {
  display: flex;
}

.link {
  width: fit-content;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px;
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: 0 3px 5px 1px rgb(0, 0, 0, 0.3);
}
.link-text {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 0 8px;
  font: 20px sans-serif;
  color: white;
}
.link-underline {
  width: 0;
  height: 1px;
  background: white;
  transition: width 400ms ease-in-out;
}
.link.active .link-underline,
.link:hover .link-underline {
  width: calc(100% + 16px);
}

.link.active,
.link:hover {
  box-shadow: inset -2px 3px 5px 1px rgb(0, 0, 0, 0.3), inset 2px -1px 5px 1px rgb(0, 0, 0, 0.2);
}

/* BUTTON OPEN/CLOSE NAV MENU ////////////////////// */
.button-nav-menu {
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}

.open-close-bars,
.open-close-bars::before,
.open-close-bars::after {
  width: 29px;
  height: 2px;
  display: flex;
  transition: all 300ms ease-in-out;
}

.open-close-bars::before,
.open-close-bars::after {
  content: "";
  position: absolute;
  background: white;
}

.open-close-bars::before {
  transform: translateY(-4px);
}

.open-close-bars::after {
  transform: translateY(4px);
}

/* OPEN BUTTON ANIMATION  */
.button-nav-menu.open .open-close-bars {
  transform: rotate(90deg);
  transition: all 500ms ease-in-out;
}

.button-nav-menu.open .open-close-bars::before {
  transform: rotate(45deg);
  background: magenta;
}

.button-nav-menu.open .open-close-bars::after {
  transform: rotate(135deg);
  background: magenta;
}

/* MAIN NAV MENU SMALL SCREENS ///////////////////////// */
@media (max-width: 1000px) {
  .header-right-side {
    flex-direction: row;
  }

  .close-nav-overlay {
    display: none;
  }

  .nav-menu {
    width: fit-content;
    min-width: 200px;
    height: calc(100vh - 50px);
    padding: 50px 12px;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 888;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: 0.3s ease-in-out;
    box-shadow: -1px 0 3px 1px rgb(0, 0, 0, 0.3);
    background: darkslategray;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(100%);
  }
  .nav-menu.open {
    transform: none;
  }

  .link {
    width: 90%;
  }
  .link-text {
    font-size: 1rem;
  }

  .button-nav-menu {
    display: flex;
  }
}
