/* TEXT WAVE EFFECT */
.wavy-text-effect-container {
  width: fit-content;
  height: fit-content;
  margin: 12px 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid rgb(0, 0, 255, 0.3);
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  background: linear-gradient(45deg, purple, magenta);
}
.wavy-text-effect-container h2 {
  position: relative;
  font: 700 clamp(2rem, 5vw, 3rem) Poppins, sans-serif;
  color: blue;
  /* -webkit-text-stroke: 1px gray; */
}
.wavy-text-effect-container h2::after {
  content: "wavy text";
  position: absolute;
  left: 0;
  color: aqua;
  animation: wave-text-anim 3s ease-in-out infinite;
}
@keyframes wave-text-anim {
  0%,
  100% {
    clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
  }

  50% {
    clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
  }
}
