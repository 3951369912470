/* HOME PAGE / LANDING / ACTION BUTTON / ON-HOVER */
.action-btn {
  color: white;
  box-shadow: inset -1px 2px 5px 1px rgb(255, 255, 255, 0.4), inset 1px -2px 5px 1px rgb(0, 0, 0, 0.7), -1px 2px 5px 1px rgb(0, 0, 0, 0.4);
}
.action-btn:hover {
  color: aqua;
  box-shadow: inset 0 1px 3px 1px rgb(255, 255, 255, 0.4), inset 0 -1px 3px 1px rgb(0, 0, 0, 0.7), -1px 2px 5px 1px rgb(0, 0, 0, 0.4);
}

/* GLOBAL PAGE STYLING  */
.page {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-heading {
  width: 100%;
  min-height: 40vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 25px sans-serif;
  color: white;
  filter: drop-shadow(0 0 5px aqua);
}

.page-content {
  width: 100%;
  padding: 20px;
}

.section-divider-container {
  position: relative;
  top: -80px;
}

.page-content h1 {
  width: fit-content;
  margin: 30px 0;
  padding: 10px;
  font: 400 20px PixeloidSans;
  color: white;
  border-bottom: solid var(--color-3);
  border-bottom-left-radius: 5px;
  background: linear-gradient(5deg, var(--color-3), transparent);
}

.page-content p {
  width: fit-content;
  padding: 10px;
  margin: 20px 0;
  font: 400 16px sans-serif;
  color: var(--color-2);
  border-bottom: 1px solid gray;
  border-radius: 5px;
  background: white;
}

.effects-section {
  width: 100%;
  margin-top: 88px;
  font: 25px sans-serif;
}

.effects-container {
  padding: 23px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.tut-link-container {
  width: 100%;
  max-width: 300px;
  height: fit-content;
  margin: 80px auto;
  border: 1px solid var(--color-3);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.tut-link-container h1 {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin: auto;
  color: white;
}

.tut-link-container h1:hover {
  text-decoration: underline;
}

.test-area {
  border: 1px solid aqua;
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.credits-section {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: solid var(--color-3);
}
