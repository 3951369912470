:root {
  --clr-body: #333;
  --clr-bg: #ddd;
}
.search-bar-container {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}
.search-bar-input {
  width: 100%;
  height: 100%;
  padding: 0 50px 0 10px;
  border: 0;
  border-radius: 25px;
  outline: 0;
  position: absolute;
  font: 200 1.2rem sans-serif;
  opacity: 0;
  cursor: pointer;
}
.search-btn {
  min-width: var(--size);
  height: var(--size);
  position: absolute;
  right: 0;
  z-index: 8888;
  border: none;
  cursor: pointer;
  pointer-events: none;
  background: inherit;
}
.search-btn::before {
  content: "";
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
  background: linear-gradient(135deg, skyblue, white, skyblue);
}
.search-btn::after {
  content: "";
  width: 2px;
  height: 8px;
  border: none;
  position: absolute;
  top: 22px;
  left: 26px;
  transform: rotate(-47deg);
  background: white;
}
.search-bar-container:focus-within {
  width: calc(100% - 110px);
  position: absolute;
  right: 50px;
}
@media (min-width: 700px) {
  .search-bar-container:focus-within {
    width: 500px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 8888;
    margin: auto;
    transition: width 300ms cubic-bezier(0.18, 0.89, 0.32, 1.15);
  }
}
.search-bar-container:focus-within .search-bar-input {
  opacity: 1;
  pointer-events: all;
  cursor: text;
}
.search-bar-container:focus-within .search-btn {
  pointer-events: all;
  border-radius: 25px;
  background: linear-gradient(blue, pink);
}
