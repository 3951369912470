.projects {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.project-card {
  max-width: 800px;
  margin: 33px auto;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 15px 2px rgb(255, 255, 255, 0.5);
  background: linear-gradient(to top right, black, rgb(124, 124, 124), black, rgb(158, 158, 158));
  overflow: hidden;
}

.project-card h2 {
  width: fit-content;
  height: fit-content;
  margin: 30px auto 0 auto;
  padding: 5px;
  border-bottom: 1px solid;
  filter: drop-shadow(0 0.2rem 0.4rem black);
  font-size: 20px;
}

.project-card p {
  width: 95%;
  margin: 50px auto 20px auto;
  line-height: 25px;
  filter: drop-shadow(0 0.2rem 0.4rem black);
}

.project-card li {
  width: fit-content;
  margin: 10px auto;
  transform: none;
}

.project-card li::before{
  content: "> ";
}

.responsive-project {
  width: 100%;
  aspect-ratio: 4/3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-box-reflect: below -13% linear-gradient(to top, rgb(255, 255, 255, 0.4), transparent 60%);
}

.responsive-computer {
  width: 80%;
  height: 70%;
  background: linear-gradient(135deg, rgb(218, 218, 218) 50%, white 50%, rgb(220, 220, 220));
  box-shadow: 0 0 2rem rgb(0, 0, 0, 0.4);
  border-radius: 0.4rem;
  position: relative;
}

.responsive-computer::before {
  content: "";
  width: 20%;
  height: 10%;
  background: linear-gradient(to right, rgb(197, 197, 197), rgb(213, 213, 213), rgb(197, 197, 197));
  box-shadow: 0 0 4rem 0.1rem rgb(0, 0, 0, 0.4);
  position: absolute;
  bottom: -10%;
  left: 40%;
}

.responsive-computer::after {
  content: "";
  width: 40%;
  height: 3%;
  background: linear-gradient(to right, white, rgb(213, 213, 213), white);
  /* box-shadow: 0 -0.1rem 4rem 0.6rem rgb(0, 0, 0, 0.4); */
  filter: drop-shadow(0 0 2rem black);
  position: absolute;
  bottom: -12%;
  left: 30%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.responsive-image {
  width: 96%;
  height: 92%;
  border-radius: 0.3rem;
  position: absolute;
  top: 2%;
  left: 2%;
}

.computer-button {
  width: 4%;
  height: 4%;
  border-radius: 3rem;
  background: white;
  box-shadow: inset -0.1rem 0.1rem 0.2rem rgb(78, 78, 78, 0.4);
  position: absolute;
  bottom: 1%;
  left: 48%;
}

/* .sr-only {
  width: 0;
  height: 0;
} */

.responsive-phone {
  width: 20%;
  height: 60%;
  background: linear-gradient(135deg, rgb(218, 218, 218) 30%, white 30%, rgb(220, 220, 220));
  box-shadow: -0.2rem 0.2rem 3rem rgb(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  position: absolute;
  right: -10%;
  bottom: -10%;
}

.responsive-phone img {
  width: 96%;
  height: 90%;
  border-radius: 0.2rem;
  position: absolute;
  top: 2%;
  left: 2%;
}

.responsive-phone::after {
  content: "";
  width: 12%;
  height: 5%;
  border-radius: 3rem;
  background: white;
  box-shadow: inset -0.1rem 0.1rem 0.2rem rgb(78, 78, 78, 0.4);
  position: absolute;
  bottom: 2%;
  left: 44%;
}

.button-link-project {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  padding: 10px;
  font: 600 20px Raleway;
  color: var(--blue-chrome-3);
  border-bottom-left-radius: 0.4rem;
  background: linear-gradient(to bottom left, rgb(0, 255, 255, 0.7) 40%, rgb(0, 0, 128, 0.8));
  box-shadow: inset 4px -4px 7px 1px rgb(0, 0, 0, 0.4);
  clip-path: polygon(100% 100%, 0 100%, 0 0);
}

.button-link-project:hover {
  background: rgb(0, 255, 255, 0.5);
  text-decoration: underline;
}

.project-images-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.project-image-link-container {
  width: 95%;
  flex-basis: 45%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-image-link-container-2 {
  width: 95%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-image {
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  -webkit-box-reflect: below 3% linear-gradient(to top, rgb(255, 255, 255, 0.3), transparent, transparent);
  box-shadow: 0 0 10px 4px rgb(0, 0, 0, 0.4);
}

.project-link {
  width: 100px;
  height: 50px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: relative;
  top: -30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: linear-gradient(rgb(0, 255, 255, 0.8), rgb(0, 0, 128, 0.8));
}

