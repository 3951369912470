.picture-cube-container {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 600px;
  perspective-origin: 50%;
  overflow: hidden;
  background: linear-gradient(white, skyblue 30% 50%, lightgray 52%, white);
}

.picture-cube-container-2 {
  background: url(../../../assets/images/pexels-alex-andrews-816608-edit.jpg) 50% / 100% 100%;
  border: none;
}

.click-tap-message {
  width: fit-content;
  height: fit-content;
  padding: 10px 30px;
  position: absolute;
  top: 0;
  left: -10px;
  z-index: 2;
  color: rgb(255, 255, 255, 0.7);
  background: radial-gradient(ellipse at 50%, rgb(0, 0, 0, 0.3) 40%, transparent 70%);
  pointer-events: none;
}

.picture-cube-rain-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.picture-cube-rotate-deg {
  width: 100%;
  height: 50px;
  border: 1px solid;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 400 40px sans-serif;
}

.picture-cube-back-btn,
.picture-cube-forward-btn {
  width: 50px;
  height: 100px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0.4;
}

.picture-cube-forward-btn {
  left: calc(100% - 50px);
  transform: rotateY(180deg);
}
.picture-cube-back-btn:hover,
.picture-cube-forward-btn:hover {
  background: linear-gradient(45deg, aqua, white, aqua, white);
}

.picture-cube-back-btn::before,
.picture-cube-forward-btn::before,
.picture-cube-back-btn::after,
.picture-cube-forward-btn::after {
  background: white;
  border-radius: 3px;
  content: "";
  width: 30px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform-origin: 0 100%;
  transform: rotate(-45deg) translateY(1px);
}

.picture-cube-back-btn::after,
.picture-cube-forward-btn::after {
  transform-origin: 0 0;
  transform: rotate(45deg) translateY(-1px);
}

.picture-cube-pos-z-container {
  perspective: 600px;
  perspective-origin: 50%;
}

.picture-cube-pos-z-container.anim {
  animation: picture-cube-pos-z-container 2s ease-in-out;
}
@keyframes picture-cube-pos-z-container {
  50% {
    transform: translateZ(-1000px);
    perspective: 2400px;
  }
  100% {
    transform: translateZ(0);
  }
}

.picture-cube {
  height: 300px;
  width: 300px;
  position: relative;
  transform-style: preserve-3d;
  transition: all 2s ease-in-out;
  cursor: pointer;
}
.picture-cube.anim {
  animation: picture-cube-rotateY 2s ease-in-out;
  transform: rotateY(90deg);
}

.picture-cube .side {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid;
  transform: translateZ(var(--translateZ, 0)) rotateY(var(--rotateY, 0));
  background: rgb(0, 0, 255, 0.4);
  font: 900 50px sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picture-cube .front,
.picture-cube .back {
  background: url(../../../assets/logos/webengines-512.png) 50% / 40% no-repeat, linear-gradient(45deg, rgb(0, 0, 255), rgb(64, 224, 208, 0.99), rgb(0, 0, 255, 0.85), rgb(64, 224, 208, 0.99), rgb(0, 0, 255));
}

.picture-cube .front {
  --translateZ: 150px;
}

.picture-cube .back {
  --translateZ: -150px;
  --rotateY: 180deg;
}

.picture-cube .left,
.picture-cube .right {
  width: 100%;
  transform: translateX(-150px) rotateY(-90deg);
  background: url(../../../assets/images/pexels-nic-wood-6432110-edit.jpg);
}

.picture-cube .right {
  transform: translateX(150px) rotateY(90deg);
}
